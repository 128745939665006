<template>
    <ddcard class="box-card" :collapsed="true" :inactive="!active">
        <div slot="title" class="clearfix">
            <el-switch v-model="active" :active-value="1" :inactive-value="0" />
            <span style="margin-left: 20px;">Facturis</span>
        </div>

        <Box type="info" :active="true" style="margin-top: 0; margin-bottom: 10px;">
            <div slot="box-body">
                <ul style="font-size: 12px;">
                    <li>Token-ul se poate obtine Setari > Integrari > Cheia Api</li>
                </ul>
            </div>
        </Box>

        <el-row v-if="active" :gutter="30">
            <el-col :xs="24" :md="12">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                        <el-form-item :label="$t('sales.billing.token')" prop="facturis.token">
                            <el-input v-model="token"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="6">
                        <el-form-item :label="$t('sales.billing.user')" prop="facturis.user">
                            <el-input v-model="user"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="6">
                        <el-form-item :label="$t('sales.billing.password')" prop="facturis.password">
                            <el-input v-model="password"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.product_reference')" prop="facturis.product_reference">
                            <el-select class="fullWidth" v-model="product_reference" filterable>
                                <el-option
                                    value="products_model"
                                    :label="$t('sales.billing.products_model')"
                                ></el-option>
                                <el-option value="vendor_ext_id" :label="$t('sales.billing.vendor_ext_id')"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.invoice_serie')" prop="facturis.invoice_serie">
                            <el-input v-model="invoice_serie"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.proforma_serie')" prop="facturis.proforma_serie">
                            <el-input v-model="proforma_serie"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :xs="24" :md="12">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item :label="$t('sales.billing.warehouse')" prop="facturis.warehouse">
                            <el-input v-model="warehouse"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_type')" prop="facturis.shipping_type">
                            <el-select class="fullWidth" v-model="shipping_type" filterable>
                                <el-option value="product" label="Produs"></el-option>
                                <el-option value="service" label="Serviciu"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_code')" prop="facturis.shipping_code">
                            <el-input v-model="shipping_code"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-checkbox
                            v-model="issue_payment"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.issue_payment')"
                        />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </ddcard>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'Facturis',
    computed: {
        active: sync('account/billing@facturis.active'),
        email: sync('account/billing@facturis.email'),
        token: sync('account/billing@facturis.token'),
        user: sync('account/billing@facturis.user'),
        password: sync('account/billing@facturis.password'),
        product_reference: sync('account/billing@facturis.product_reference'),
        invoice_serie: sync('account/billing@facturis.invoice_serie'),
        proforma_serie: sync('account/billing@facturis.proforma_serie'),
        warehouse: sync('account/billing@facturis.warehouse'),
        shipping_type: sync('account/billing@facturis.shipping_type'),
        shipping_code: sync('account/billing@facturis.shipping_code'),
        issue_payment: sync('account/billing@facturis.issue_payment')
    },
    components: {
        ddcard: () => import('@/components/DDCard'),
        Box: () => import('vue-info-box-element')
    }
};
</script>
